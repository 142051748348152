import {
  input,
} from '../aziziComp/form.module.css'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { addForm } from '../../action'
import { siteUrl } from '../_utils'
import { loaderText, telinputContainer } from '../fiveJBR/form.module.css'
import ReactFlag from '../reactFlagsComp-back'
import { ImSpinner } from 'react-icons/im'
import ReactGA from 'react-ga'

const FormLayout = (props) => {
  const {register, handleSubmit, formState: {errors}} = useForm()
  let [mobile, setMobile] = React.useState('')
  let [phoneCode, setCode] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const onSubmit = async (data) => {

    ReactGA.event({
      category: 'BtnClick',
      action: 'sideBarButtonClick',
    })

    if (mobile) {
      if (!phoneCode) {
        alert('Phone Code  is required')
        return
      }
    }

    if (!mobile && !data.email) {
      alert('Mobile/Email is required')
    }

    setLoading(true)

    let x = await addForm({...data, form: 'sobhaHARTLANDPlots', phoneCode, mobile: mobile})
    setLoading(false)

    if (!x.error) {
      window.location.href = `${siteUrl}thankuPage`
    } else {
      console.log(x)
    }

  }

  return (<div className={`bg-white`}>

      <div className={'p-6 pt-0 sm:px-5 '}>
        <div className={'my-4 mt-0 text-center'}>
          <button disabled={loading}
                  style={{flex: 1, alignContent: 'center'}}
                  className={'w-10/12 rounded  text-sm  bg-primary-darkGold py-3 px-4 text-white'}
                  type="submit">
            <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>

            {/*<span>Download Payment Plan</span>*/}

          </button>
        </div>
        <div className={'my-4 text-center'}>
          <button disabled={loading}
                  style={{flex: 1, alignContent: 'center'}}
                  className={'w-10/12 rounded text-primary-darkGold text-sm border-2 border-primary-darkGold py-3 text-darkGold'}
                  type="submit">
            <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>

            <span>Project Master/Plan Brochure</span>

          </button>
        </div>
      </div>

      <div className={'p-6 border border-primary-gray border-opacity-20'}>
        <div className={'text-l text-center text-sm text-primary-lightGray text-primary-darkGold'}>
          Most awaited <strong>Forest and Waterfront Villa Plots</strong> in Sobha Hartland MBR City are ready for sale
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={'my-4'}><input
            className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
            type="text" placeholder="Name" id={'name'} {...register('name')} required={true}/></div>
          <div className={'flex my-4'}>
            <div className={`border border-primary-gray border-opacity-20 ${telinputContainer}`}>
              <ReactFlag countryISO={props.countryISO} footerFlag={false} flagValue={(value) => {
                if (value && value.mobile) {
                  setMobile(value.mobile)
                  setCode(value.code)
                } else {
                  setCode('')
                  setMobile('')
                }
              }}/>
            </div>

          </div>
          <div className={'my-4'}><input
            className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
            type="email" placeholder="Email" id={'email'} {...register('email')} /></div>
          <div className={'my-4 text-center'}>
            <button disabled={loading}
                    style={{flex: 1, alignContent: 'center'}}
                    className={'w-10/12 rounded text-sm bg-primary-darkGreen py-3 px-4 text-white'}
                    type="submit">
              <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>

              <span>Schedule an Appointment</span>

            </button>
          </div>

          <div className={'text-center font-bold text-lg text-primary-gray'}>
            BOOKINGS OPEN!
          </div>
          <div className={'text-center text-sm text-primary-gray'}>
            Book Your Plot with 10% only
          </div>

        </form>

      </div>

    </div>
  )
}

export default FormLayout
