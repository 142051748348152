
import * as React from "react";
import footerImage from "../../images/BNW_logo_png.png";

const Footer = () => {
    return (<>
        {/*<div>*/}
        {/*    <div className={'pb-9 sm:pb-12'}>*/}
        {/*        <StaticImage*/}
        {/*            width={1500}*/}
        {/*            src={'../../images/waves/banner5.webp'}*/}
        {/*            alt={'photo'}/>*/}
        {/*    </div>*/}

        {/*</div>*/}
        {/*<FooterFormLayout showPara={true}  heading={'BOOK A FREE CONSULTATION'} buttonName={'Submit'}/>*/}

        <div className={'bg-primary-gray'}>
            <div className={'container  py-16'}>
                <img className={'m-auto'} src={footerImage}
                     alt=""/>
            </div>

        </div>
    </>)
}

export default Footer
