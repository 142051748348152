import {
  headerSection,
  mainSection,
} from './heroArea.module.css'

import FormLayout from './form'
import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Slider from 'react-slick'

const settings = {
  dots: false,
  infinite: true,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
}
const HeroArea = ({countryISO, short, symbol}) => {

  return (
    <>
      <div className={mainSection}>
        <section className={`${headerSection} text-center bg-center md:bg-center font-lato grid-cols-12`}>

          <div>
            <Slider {...settings}>
              <div>
                <StaticImage
                  width={1500}
                  height={650}
                  src={'../../images/shobhaLand/slider1.png'}
                  alt={'Azizi '}
                />
              </div>
              <div>
                <StaticImage
                  width={1500}
                  height={650}
                  src={'../../images/shobhaLand/slider2.png'}
                  alt={'Azizi '}
                /></div>
              <div>
                <StaticImage
                  width={1500}
                  height={650}
                  src={'../../images/shobhaLand/slider3.jpeg'}
                  alt={'Azizi '}
                /></div>
            </Slider>
          </div>

          {/* <div
            className={`absolute top-0 right-24 mt-8 `}>

            <div className={'bg-white border-t-8 border-b-8 border-darkGold p-6 '}>
              <div className={'my-4 flex justify-center  align-center'}>
                <StaticImage
                  className={'h-auto'}
                  src={'../../images/openHouse.png'}
                  alt={'open house sobha hartland plots'}/>
              </div>

              <div className={'text-l text-center text-md text-primary-darkGold mb-4 font-semibold'}>
                18 Dec 2021 AT SOBHA HARTLAND
              </div>

              <div className={'text-l text-center text-sm text-primary-lightGray'}>
                Most awaited <strong>Forest and Waterfront Villa Plots</strong> <br/> in Sobha Hartland MBR City are
                ready for
                sale
              </div>
            </div>

          </div>*/}

          <div
            className={'hidden sm:block absolute top-0 right-24 grid-cols-4 bg-white p-6 h-full mt-8 border-t-8 border-darkGold'}>
            <FormLayout countryISO={countryISO}/>
          </div>

          <div className={'sm:hidden block'}>
            <FormLayout countryISO={countryISO}/>
          </div>

        </section>

      </div>

    </>
  )
}

export default HeroArea
