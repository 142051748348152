import {
  input,
} from '../aziziComp/form.module.css'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { addForm } from '../../action'
import { siteUrl } from '../_utils'
import { loaderText, telinputContainer } from '../fiveJBR/form.module.css'
import ReactFlag from '../reactFlagsComp-back'
import { ImSpinner } from 'react-icons/im'
import { StaticImage } from 'gatsby-plugin-image'
import ReactGA from 'react-ga'

const FormLayout = (props) => {

  const {register, handleSubmit, formState: {errors}} = useForm()
  let [mobile, setMobile] = React.useState('')
  let [phoneCode, setCode] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const onSubmit = async (data) => {
    // if (!mobile) {
    //     alert('Mobile Number is required')
    //     return
    // }
    ReactGA.event({
      category: 'BtnClick',
      action: 'sliderButtonClick',
    })

    if (mobile) {
      if (!phoneCode) {
        alert('Phone Code  is required')
        return
      }
    }

    if (!mobile && !data.email) {
      alert('Mobile/Email is required')
    }

    setLoading(true)

    let x = await addForm({...data, form: 'sobhaHARTLANDPlots', phoneCode, mobile: mobile})
    setLoading(false)

    if (!x.error) {
      window.location.href = `${siteUrl}thankuPage`
    } else {
      console.log(x)
    }

  }

  return (<div className={`sm:px-6 md:max-w-sm m-auto bg-white`}>

      <div className={'my-4 flex justify-center  align-center'}>
        <StaticImage
          className={'h-auto'}
          src={'../../images/openHouse.png'}
          alt={'open house sobha hartland plots'}/>
      </div>

      <div className={'text-l text-center text-md text-primary-darkGold mb-4 font-semibold'}>
        18 Dec 2021 AT SOBHA HARTLAND
      </div>

      <div className={'text-l text-center text-sm text-primary-lightGray'}>
        Most awaited <strong>Forest and Waterfront Villa Plots</strong> in Sobha Hartland MBR City are ready for
        sale
      </div>

      <form className={'px-6 sm:px-0'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'my-4'}><input
          className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
          type="text" placeholder="Name" id={'name'} {...register('name')} required={true}/></div>

        <div className={'flex my-4'}>
          <div className={`border border-primary-gray border-opacity-20 ${telinputContainer}`}>
            <ReactFlag countryISO={props.countryISO} footerFlag={false} flagValue={(value) => {
              if (value && value.mobile) {
                setMobile(value.mobile)
                setCode(value.code)
              } else {
                setMobile('')
                setCode('')
              }
            }}/>
          </div>

        </div>
        <div className={'my-4'}><input
          className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
          type="email" placeholder="Email" id={'email'} {...register('email')} /></div>
        <div className={'my-4'}>
          <button disabled={loading}
                  style={{flex: 1, alignContent: 'center'}}
                  className={'w-10/12 rounded text-lg font-bold hover:bg-primary-gray bg-primary-darkGreen py-3 px-4 text-white'}
                  type="submit">
            <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>

            <span>Reserve your Seat</span>
          </button>
        </div>

        <div className={'text-center text-lg font-light text-primary-darkGold'}>
          BOOKINGS OPEN!
        </div>
        <div className={'text-center text-sm text-primary-darkGold'}>
          Book Your Plot with 10% only
        </div>

      </form>


    </div>
  )
}

export default FormLayout
