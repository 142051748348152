import * as React from 'react'
import CanalView from '../../images/shobhaLand/canalview.png'
import GreenView from '../../images/shobhaLand/grenspace.png'
import KidsArea from '../../images/shobhaLand/kidsarea.png'
import PrivatePool from '../../images/shobhaLand/privatepool.png'
import Schools from '../../images/shobhaLand/schools.png'
import WaterfrontLiving from '../../images/shobhaLand/waterfrontliving.png'

import { StaticImage } from 'gatsby-plugin-image'
import { sobhaHeading, sliderFooter, sliderFooterContainer } from '../sobhaVilla1Comp/heroArea.module.css'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  slidesToShow: 2,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1008,
      settings: {
        slidesToShow: 1,
      },
    }],
}
const Eminity = () => {
  return (<>
    <div className={`container border-b-2 border-primary-darkGold`}>
      <div>
        <h2 className={sobhaHeading}>Master Plan</h2>

        <div className={'pb-6 sm:pb-8'}>
          <StaticImage
            src={'../../images/shobhaLand/masterPlan.png'}
            alt={'photo'}/>
        </div>
      </div>
      <div>
        <h2 className={sobhaHeading}>Available Plots </h2>

        <div className={'pb-6 sm:pb-8'}>
          <StaticImage
            src={'../../images/shobhaLand/plots.png'}
            alt={'photo'}/>
        </div>
      </div>

      <div>
        <h2 className={sobhaHeading}>18 Corner Plots</h2>

        <div className={'pb-6 sm:pb-8'}>
          <StaticImage
            src={'../../images/shobhaLand/corner-plots1.png'}
            alt={'photo'}/>
        </div>
      </div>

      <div>
        <h2 className={sobhaHeading}>7 Waterfront Plots</h2>

        <div className={'pb-6 sm:pb-8'}>
          <StaticImage
            src={'../../images/shobhaLand/canal-view1.png'}
            alt={'photo'}/>
        </div>
      </div>

    </div>

    <div className={`container py-6 border-b-2 border-primary-darkGold`}>
      <div className={'text-left text-primary-darkGold'}>
        <h2 className={'font-normal text-sm pb-0 sm:text-2xl font-robotoCondensed m-auto'}>Design your villa
          according to the lifestyle you desire</h2>
        <p className={`py-4 md:text-xs  text-xxs text-primary-lightGrayTxt w-full text-left`}>The villa
          plots are primed to suit the lifestyle preferences of new-age buyers. With easy access to some of
          the key landmarks and because of the excellent connectivity options, the residential project is
          poised to become the next address of choice for those select few that revel in living the
          high-luxury life.
        </p>
      </div>
      <div className={`footerSlider ${sliderFooterContainer}`}>
        <Slider {...settings}>

          <div className={sliderFooter}>
            <img src={CanalView} alt=""/>
            <p>Customised Design with Canal view</p>
          </div>

          <div className={sliderFooter}>
            <img src={KidsArea} alt=""/>
            <p>Private Kids Play Area</p>
          </div>

          <div className={sliderFooter}>
            <img src={PrivatePool} alt=""/>
            <p>Design your Private Pool</p>
          </div>

          <div className={sliderFooter}>
            <img src={WaterfrontLiving} alt=""/>
            <p> WATER-FRONT LIVING</p>
          </div>

          <div className={sliderFooter}>
            <img src={GreenView} alt=""/>
            <p>30% OPEN & GREEN SPACES</p>
          </div>

          <div className={sliderFooter}>
            <img src={CanalView} alt=""/>
            <p>1.8 Km BOARDWALK</p>
          </div>

          <div className={sliderFooter}>
            <img src={Schools} alt=""/>
            <p>2 INTERNATIONAL SCHOOLS</p>
          </div>

        </Slider>

      </div>

    </div>

    <div className={`container py-6 text-left`}>
      <div>
        <h2
          className={'font-normal text-sm md:pb-2 pb-0 sm:text-2xl tracking-widest text-primary-darkGold mb-2'}>Proximity</h2>

        <div className={'pb-6 sm:pb-8'}>
          <StaticImage
            src={'../../images/shobhaLand/image3.png'}
            alt={'photo'}/>
        </div>

        <div className={'pb-6 sm:pb-8'}>
          <StaticImage
            src={'../../images/shobhaLand/image4.png'}
            alt={'photo'}/>
        </div>
      </div>

    </div>

  </>)
}

export default Eminity
