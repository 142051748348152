import * as React from 'react'
import { sobhaList } from '../sobhaVilla1Comp/heroArea.module.css'
import { BsCheckCircle } from 'react-icons/bs'

const Connectivity = () => {
  return (<>
    <div>
      <div className={'text-left text-primary-darkGold'}>
        <h2 className={'font-robotoCondensed font-light text-md pb-0 sm:text-4xl m-auto mb-2'}>
          Design Your Own Villa at Your Own Land
        </h2>
        <strong>in Sobha Hartland, MBR City</strong>
        <p className={`py-4 md:text-base  text-xxs text-primary-lightGrayTxt sm:w-2/3 w-full text-left`}>
          Extended development launched by Sobha Group and features 71 Exclusive villa
          plots, within Sobha Hartland in Dubai. Own a fine mix of waterfront & forest villa plots to build a
          unique blend of urban lifestyle with contemporary design.
        </p>
      </div>
      <div className={'sm:my-5 text-left text-primary-gray  text-base'}>

        <ul className={`${sobhaList} leading-6`}>
          <li>
            <BsCheckCircle/>
            Exclusive 7 Waterfront and 64 Forest Villa plots
          </li>
          <li>
            <BsCheckCircle/>
            Sizes ranging from 5029 sq.ft - 6,958 sq.ft
          </li>
          <li>
            <BsCheckCircle/>
            Attractive Payment Plan of 10% - 10% - 80%
          </li>
          <li>
            <BsCheckCircle/>
            Only Freehold Plots available in Dubai right now.
          </li>
        </ul>

      </div>
    </div>
  </>)
}

export default Connectivity
