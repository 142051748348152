import * as React from 'react'
import {} from '../components/sobhaVilla1Comp/heroArea.module.css'
import NavHeader from '../components/NavHeader'
import HeroArea from '../components/sobhaVilla1Comp/heroArea'
import Connectivity from '../components/sobhaVilla1Comp/connectivities'
import Eminity from '../components/sobhaVilla1Comp/eminity'
import Plans from '../components/sobhaVilla1Comp/plans'
import Footer from '../components/sobhaVilla1Comp/footer'
import StickyForm from '../components/sobhaVilla1Comp/stickyForm'
import PrivatePool from '../images/shobhaLand/privatepool.png'

import { StaticImage } from 'gatsby-plugin-image'
import ModalPage from '../../src/components/modalPage'
import SEO from '../components/App/SEO'
import { siteUrl } from '../components/_utils'
import { useEffect, useState } from 'react'
import { getCountry } from '../action'
import { useScrollTracker } from 'react-scroll-tracker'
import ReactGA from 'react-ga'
import { contentDiv } from '../styles/sobha.module.css'
import StickyBox from 'react-sticky-box'
import OpenHouse from '../images/openHouse.png'

const shortNumber = require('short-number')

let dollarAmount = 265450

const SobhaVilla1 = ({data}) => {
  const [countryISO, setCountryISO] = useState('ae')
  const [short, setShort] = useState(shortNumber(dollarAmount))
  const [symbol, setSymbol] = useState('$')

  useEffect(() => {
    getCountry({amount: dollarAmount}).then((data) => {
      console.log(data)
      if (data.short) {
        setShort(data.short)
      }
      if (data.symbol) {
        setSymbol(data.symbol)
      }
      if (data.iso) {
        setCountryISO(data.iso.toLowerCase())
      }
    })
  }, [])

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScrollTracker([10, 15, 25, 50, 75, 100], ({scrollY}) => {
      ReactGA.event({
        category: 'scroll',
        action: scrollY,
      })
    })
  }

  const logoComp = (
    <StaticImage
      className="w-32 md:w-56 lg:w-56  align-middle"
      placeholder="tracedSVG"
      src={'../images/shobhaLand/shoba-bnw-logo.png'}
      alt={'Sobha Hartland'}
    />
  )

  const titleParams = {
    title: `Sobha Harland Extension Plots | ${siteUrl}`,
    ogTitle: `Sobha Harland Extension Plots | ${siteUrl}`,

    description: 'Waterfront & forest villa plots, launched by Sobha Group is a new development in the Sobha Hartland master-planned development, right in the heart of Dubai.',
    ogDescription: 'Waterfront & forest villa plots, launched by Sobha Group is a new development in the Sobha Hartland master-planned development, right in the heart of Dubai.',
    keywords: '2 YEAR POST HANDOVER PAYMENT PLAN',

    secureImageUrl: `${PrivatePool}`,
    ogImage: `${PrivatePool}`,
    ogImageAlt: 'Sobha Harland',

    orUrl: `${siteUrl}sobha-villa-plots-1/`,
    pageLink: `${siteUrl}sobha-villa-plots-1/`,
    canonicalRef: `${siteUrl}sobha-villa-plots-1/`,
  }

  return (
    <>
      <SEO data={titleParams}/>
      <NavHeader logoComp={logoComp}/>
      <HeroArea countryISO={countryISO} short={short} symbol={symbol}/>

      <div className={`${contentDiv}`}>
        <div className="grid grid-cols-12 gap-1">

          <div className="bg-red col-span-12 lg:col-span-8 lg:pr-16">
            <Connectivity/>
            <Eminity/>
          </div>

          <div className="col-span-4 hidden lg:block">
            <StickyBox offsetTop={20} offsetBottom={20}>
              <StickyForm countryISO={countryISO}/>
            </StickyBox>
          </div>
        </div>

      </div>

      {/*<Plans/>*/}
      <Footer/>

    </>
  )
}

export default SobhaVilla1
